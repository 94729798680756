@import "Config/ThemeVariables.less";

.delete-user-modal {
  img {
    margin-bottom: 20px;
  }

  h4 {
    color: @text-color;
    margin-bottom: 8px;
  }

  h5 {
    color: @gray-text;
    margin: 0 !important;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: center;
    margin: 22px 0 10px;

    button {
      width: 30%;
      font-weight: 600;
    }
  }
}

.skeleton-button {
  .ant-skeleton-button {
    height: 35vh !important;
  }
}

.skeleton-loader {
  .ant-skeleton-button {
    height: 10vh !important;
  }
}

.deleteHeader {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  /* 157.143% */
}

.deleteText {
  p {
    margin-bottom: 5px;
  }
}

.keyDisplay {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.375rem;
  /* 157.143% */
}

.headerButton {
  span {
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
  }
}

.changeForm {
  input {
    border-radius: none !important;
    border: none !important;
    background: white;
  }

  // span {
  //   border-radius: 100px;
  //   border: 1px solid #d9d9d9;
  //   background: white;
  // }
}

li:has(.bg) {
  /* styles for the parent when it contains a child with the class 'has-special-class' */
  background: var(--Primary-1, #e1f1f9);
}

.avatar-wrap {
  gap: 11px;
}

:where(.css-dev-only-do-not-override-1qbztl0).ant-select
  .ant-select-selection-item,
:where(.css-1qbztl0).ant-select .ant-select-selection-item {
  max-width: 300px;
}

.w-70 {
  width: 70%;
}

:where(.css-dev-only-do-not-override-1qbztl0).ant-select-multiple
  .ant-select-selection-overflow {
  margin: 0;
}

.ant-select-selector .ant-select-selection-overflow .ant-select-selection-item {
  border-radius: 12px;
  margin-right: 0;
}

.overlay-table table {
  width: 100% !important;
}

:where(.css-dev-only-do-not-override-1qbztl0).ant-table-wrapper
  .overlay-table
  .ant-table.ant-table-small
  .ant-table-expanded-row-fixed {
  max-width: 100% !important;
}

:where(.css-1qbztl0).ant-drawer {
  z-index: 999 !important;
}

:where(.css-dev-only-do-not-override-1qbztl0).ant-spin-nested-loading {
  z-index: 99;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.ant-table a.primary-link {
  color: #1677ff;
}

.ant-modal-content .range-border-none input {
  border: none;
}

.reportviewer-container {
  max-width: 100%;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

:where(.css-dev-only-do-not-override-1qbztl0).ant-table-wrapper
  .ant-table-column-sorters::after,
.ant-table-column-sorters::after {
  display: none !important;
}

.status-code {
  position: relative;
  padding-left: 14px;

  &::after {
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    left: 0;
    top: calc(50% - 3px);
    content: "";
  }
}

.error-status {
  &::after {
    background-color: #ff4d4f;
  }
}

.success-status {
  &::after {
    background-color: #52c41a;
  }
}

.custom_multiselection .ant-select-selection-overflow {
  margin: 0 !important;
}

.d-none {
  display: none !important;
}
.border-less .ant-select-selector {
  border: none !important;
  background-color: transparent !important;
}

.border-less {
  width: 90px !important;
}

.fixed-table table {
  table-layout: fixed !important;
}

.ant-input-group-addon {
  width: 0 !important;
}

.custom_multiselection .ant-select-selection-overflow {
  margin: 0 !important;
}

.text-wrap {
  text-wrap: nowrap !important;
}

.userpilot-btn {
  text-decoration: none;
  position: absolute;
  right: -2%;
  top: 30%;
  transform: rotate(-90deg);
  padding: 15px;
  background-color: #ff7454;
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  z-index: 999;
}
.pl-0 {
  padding-left: 0;
}
.datepicker {
  border-radius: 20px;

  .ant-picker-input input {
    border: none;
  }
}
.fw-bold {
  font-weight: 700;
}

.drk-gray {
  color: rgb(97, 97, 97) !important;
}